import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/hayden/dev/fluidsolarthermal.com/web/src/components/layout-markdown.tsx";
import SEO from '../../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Press" mdxType="SEO" />
    <div className="relative">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1920px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f743f6628c4467bbf163fa41ea936afb/b89a9/press-john.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEEA//EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAUo8EqIAf//EABsQAAIDAAMAAAAAAAAAAAAAAAECAwQREiEj/9oACAEBAAEFAlsMwSfk0mB8ArgerdN//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHRAAAgEEAwAAAAAAAAAAAAAAAAERAgMSQSEjMv/aAAgBAQAGPwL3BFVx8j7WTsx1Iz//xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhMUFhcf/aAAgBAQABPyEw0FbEogwKcZUr/YsyJrzCSvhBQOT/2gAMAwEAAgADAAAAEKfv/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/EJiv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHxABAAIBAwUAAAAAAAAAAAAAAQARIUFxgTFRYZGh/9oACAEBAAE/EEueqqCJxt7lEIrRNJ8duYFaxysP2J4wQVnrZrtCQb1bJiGCoNGZ/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "john",
              "title": "john",
              "src": "/static/f743f6628c4467bbf163fa41ea936afb/b89a9/press-john.jpg",
              "srcSet": ["/static/f743f6628c4467bbf163fa41ea936afb/c635c/press-john.jpg 500w", "/static/f743f6628c4467bbf163fa41ea936afb/7e3f6/press-john.jpg 1000w", "/static/f743f6628c4467bbf163fa41ea936afb/b89a9/press-john.jpg 1920w"],
              "sizes": "(max-width: 1920px) 100vw, 1920px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
  <div className="absolute w-full h-full top-0 left-0 justify-center flex-col items-center flex">
    <h1 className="text-4xl text-white lg:text-8xl md:text-6xl sm:text-4xl font-extrabold text-accent-light">
      Press
    </h1>
  </div>
    </div>
    <h2 {...{
      "id": "press-clippings"
    }}>{`PRESS CLIPPINGS`}</h2>
    <h3 {...{
      "id": "architecture--design-aug-31-2017"
    }}>{`Architecture & Design (Aug 31, 2017)`}</h3>
    <p>{`Hybrid office building cuts cord to electricity grid`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.architectureanddesign.com.au/features/comment/hybrid-office-building-cuts-cord-to-electricity-gr"
      }}>{`https://www.architectureanddesign.com.au/features/comment/hybrid-office-building-cuts-cord-to-electricity-gr`}</a></p>
    <h3 {...{
      "id": "australian-financial-review-aug-28-2017"
    }}>{`Australian Financial Review (Aug 28 2017)`}</h3>
    <p>{`Fluid Solar banks on solar thermal`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.afr.com/business/energy/solar-energy/fluid-solar-banks-on-solar-thermal-20170828-gy5heu"
      }}>{`http://www.afr.com/business/energy/solar-energy/fluid-solar-banks-on-solar-thermal-20170828-gy5heu`}</a></p>
    <h3 {...{
      "id": "business-news-australia-aug-28"
    }}>{`Business News Australia (Aug 28)`}</h3>
    <p>{`GOING OFF THE GRID: THE COMPANY THAT COULD SOLVE SOUTH AUSTRALIA’S ENERGY WOES`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.businessnewsaus.com.au/articles/going-off-the-grid%E2%80%93the-company-that-could-solve-south-australia-s-energy-woes.html"
      }}>{`https://www.businessnewsaus.com.au/articles/going-off-the-grid–the-company-that-could-solve-south-australia-s-energy-woes.html`}</a></p>
    <h3 {...{
      "id": "impress-media-aug25-2017"
    }}>{`Impress Media (Aug25 2017)`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.impress.com.au/newsroom/fluid-solar/2132-world-first-solar-thermal-building-succeeds-off-grid.html"
      }}>{`https://www.impress.com.au/newsroom/fluid-solar/2132-world-first-solar-thermal-building-succeeds-off-grid.html`}</a></p>
    <h3 {...{
      "id": "the-australian-feb-21-2017"
    }}>{`The Australian (Feb 21 2017)`}</h3>
    <p>{`Fluid House leads world with switch to working off-grid`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.theaustralian.com.au/national-affairs/climate/fluid-house-leads-world-with-switch-to-working-offgrid/news-story/4a7c378d9e810dd0b9ee4454becc466e"
      }}>{`https://www.theaustralian.com.au/national-affairs/climate/fluid-house-leads-world-with-switch-to-working-offgrid/news-story/4a7c378d9e810dd0b9ee4454becc466e`}</a></p>
    <hr></hr>
    <p>{`Contact us about your next project`}</p>
    <p>{`127 Haydown Rd, Elizabeth Vale SA 5112, Australia`}</p>
    <p>{`enquiries@fluidsolarthermal.com`}</p>
    <p>{`+618 8440 7788
+61 414 450 215`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      